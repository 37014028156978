import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { StyledModal } from "../../../common/materials/GenericStyleComponent";
import NegotiateConfirmation from "./NegotiateConfirmation";
import { convertProductPrice, getDetailsDescription } from "../TableProps";
import { negotiateHoldProduct } from "../../../redux/slice/pendingProductteppo/pendingProductsSlice";
import { AppDispatch } from "../../../redux/store";
import { useDispatch } from "react-redux";
import {
  StyledButton,
  StyledAuctionEndedWrapper,
  StyledWrapper,
  StyledComponent,
  StyledDescription,
} from "./NegotiatePopupStyle";
import ViewHoldProductWinnerModal from "./ViewHoldProductWinnerModal";
import ProductDescription from "../../shared/ProductDescription";
import Cross from "../../../pages/LandingPage/Static/Icons/Cross";
import ViewNegotiationWinnerModal from "./ViewNegotiationWinnerModal";
import styled from "styled-components";

interface IProps {
  productDetails?: any;
  closeModal: () => void;
}

const StyledTextWrapper = styled.div`
  display: block;
  text-align: center;
  align-items: center;
  font-size: 1.5em;
  font-family: Calibre-Regular;
  color: ${(props) => props.theme.colors.base.black};
`;

const DoYouWishNegotiatePopup = React.forwardRef((props: IProps, ref) => {
  const { productDetails } = props;
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();

  const [showNegotiateConfirmationModal, setShowNegotiateConfirmationModal] =
    useState<boolean>(false);
  const [showViewHoldProductWinnerModal, setShowViewHoldProductWinnerModal] =
    useState<boolean>(false);
  const [showNegotiationWinnerModal, setShowNegotiationWinnerModal] =
    useState<boolean>(false);
    const [userNagotiatePrice,setUserNagotiatePrice] = useState("")

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    const { perId, auctionId } = productDetails;

    const formData = {
      negotiatePrice: 0,
      productDescription: getDetailsDescription(productDetails, t),
      perId: perId,
      accept: true,
      auctionId: auctionId,
    };

    const resultAction: any = await dispatch(negotiateHoldProduct(formData));
    if (negotiateHoldProduct.fulfilled.match(resultAction)) {
      //fulfilled
      setShowNegotiateConfirmationModal(false);
      setShowViewHoldProductWinnerModal(true);
    } else if (resultAction.payload) {
      setShowNegotiateConfirmationModal(false);
      setShowViewHoldProductWinnerModal(true);
    }
  };

  const { closeModal } = props;

  const renderNegotiateConfirmationModal = () => {
    return (
      <StyledModal open={showNegotiateConfirmationModal}>
        <NegotiateConfirmation
          closeModal={() => {
            setShowNegotiateConfirmationModal(false);
            closeModal();
          }}
          handleNegotiateFormSubmit={(userNagotiatePrice:any)=>{
            setUserNagotiatePrice(userNagotiatePrice)
            setShowNegotiationWinnerModal(true)
            setShowNegotiateConfirmationModal(false)
          }}
          negotiableProductDetails={productDetails}
        />
      </StyledModal>
    );
  };

  const renderViewHoldProductWinnerModal = () => {
    return (
      <StyledModal open={showViewHoldProductWinnerModal}>
        <ViewHoldProductWinnerModal
          closeModal={() => {
            setShowViewHoldProductWinnerModal(false);
            closeModal();
          }}
          negotiableProductDetails={productDetails}
        />
      </StyledModal>
    );
  };

  const renderNegotiationWinnerModal = () => {
    return (
      <StyledModal open={showNegotiationWinnerModal}>
        <ViewNegotiationWinnerModal
          closeModal={() => {
            setShowNegotiationWinnerModal(false);
            closeModal();
          }}
          userNagotiatePrice={userNagotiatePrice}
          negotiableProductDetails={productDetails}
        />
      </StyledModal>
    );
  };

  return (
    <StyledComponent style={{ position: "relative" }} tabIndex={-1}>
      <div
        onClick={closeModal}
        style={{ position: "absolute", top: 10, right: 10 }}
      >
        <Cross />
      </div>
      <StyledWrapper container spacing={0}>
        <StyledAuctionEndedWrapper item md={12} xs={12} lg={12}>
          <StyledTextWrapper>
            <span style={{ fontSize: 16 }}>
              {t("common:pendingProduct:text:DoYouWishNegotiatePopupFirst")}
            </span>
          </StyledTextWrapper>
          <StyledDescription style={{ fontSize: 20 }}>
            <ProductDescription
              truncateLength={1000}
              data={productDetails}
              isTendor={true}
            />
          </StyledDescription>
          <span style={{ fontSize: 16 }}>
            {t("common:pendingProduct:text:descriptionSecondText")}{" "}
            {convertProductPrice(
              productDetails,
              productDetails?.caratSellingPrice
            )}{" "}
          </span>

          <div style={{ display: "flex" }}>
            <StyledButton onClick={handleFormSubmit}>
              {t("common:pendingProduct:button:yes")}
            </StyledButton>
            <StyledButton
              onClick={() => setShowNegotiateConfirmationModal(true)}
            >
              {t("common:pendingProduct:button:negotiate")}
            </StyledButton>
          </div>
        </StyledAuctionEndedWrapper>
      </StyledWrapper>
      {showNegotiateConfirmationModal && renderNegotiateConfirmationModal()}
      {showViewHoldProductWinnerModal && renderViewHoldProductWinnerModal()}
      {showNegotiationWinnerModal && renderNegotiationWinnerModal()}
    </StyledComponent>
  );
});

export default DoYouWishNegotiatePopup;
