import React, { useEffect } from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { convertProductPrice } from "../TableProps";
import ProductDescription from "../../shared/ProductDescription";
import { StyledButton } from "./NegotiatePopupStyle";

const StyledComponent = styled(Grid)`
  outline: none;
  .secondaryLabel {
    font-size: 1.6em;
    font-weight: bold;
    color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  }
  .text {
    font-size: 1em;
    font-weight: bold;
    padding: 10px;
    color: ${(props) => props.theme.colors.base.grey};
  }

  .textDesc {
    font-size: 1.6em;
    font-weight: bold;
    padding: 8px;
    color: ${(props) => props.theme.colors.base.black};
  }

  .paddingBottom {
    padding-bottom: 20px;
  }
`;

const StyledWrapper = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  width: 300px;
  text-align: center;
  padding: 0.5em;
  border-radius: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 300px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 400px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 500px;
  }
`;

const StyledTextWrapper = styled.div`
  display: block;
  text-align: center;
  align-items: center;
  font-size: 1.2em;
  font-family: Calibre-Regular;
  color: ${(props) => props.theme.colors.base.black};
`;

const StyledWinnerWrapper = styled(Grid)`
  text-align: center;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    padding: 1em 1em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    padding: 1em 2em;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    padding: 1em 2em;
  }
`;

export interface IProps {
  closeModal: () => void;
  negotiableProductDetails: any;
  userNagotiatePrice: any;
}

const ViewNegotiationWinnerModal = React.forwardRef((props: IProps, ref) => {
  const { closeModal, negotiableProductDetails, userNagotiatePrice } = props;

  const { t } = useTranslation();

  return (
    <StyledComponent tabIndex={-1}>
      <StyledWrapper container spacing={0}>
        <StyledWinnerWrapper item md={12} xs={12} lg={12}>
          <StyledTextWrapper>
            {t("common:pendingProduct:modal:negotiationSuccessfully")}
          </StyledTextWrapper>
          <div className="textDesc">
            <ProductDescription
              truncateLength={1000}
              data={negotiableProductDetails}
              isTendor={false}
            />
          </div>
          <div className="text">
            <StyledTextWrapper>
              {t("common:pendingProduct:modal:negotiationWinnerText")}
              {convertProductPrice(
                negotiableProductDetails,
                userNagotiatePrice
              )}
            </StyledTextWrapper>
          </div>
          <StyledButton onClick={closeModal}>
            {t("common:pendingProduct:button:okay")}
          </StyledButton>
        </StyledWinnerWrapper>
      </StyledWrapper>
    </StyledComponent>
  );
});

export default ViewNegotiationWinnerModal;
