export const englishVideoUrl = 'https://youtu.be/i4rR6zU8xmw';
export const japaneseVideoUrl = 'https://youtu.be/0TtYyCFdT3U';
export const newEnglishVideoUrl = "https://www.youtube.com/watch?v=5bXpZ2Wrs8k";
export const newJapaneseVideoUrl = "https://www.youtube.com/watch?v=H9KB9zKM3Nc";
export const LangingPageVideoUrl = 'http://youtu.be/jvLBnC9dUsI';

let environmentVariable = process.env.REACT_APP_SERVICE_API_URL;
let environmentVariableForWebsocket = process.env.REACT_APP_WEBSOCKET_API_URL;
// let environmentVariableForWebsocket = `https://myauctionstech.in`;
// let environmentVariableForWebsocket = `https://myauctionsdemo.in`;
export const domainName = `${environmentVariable}`;

export const url = `${environmentVariable}`;
// export const url:any = `https://myauctionstech.in`;
// export const url = `http://myauctionsdemo.in`;
export let b = {
  baseUrl: `${url}/keibai-service`,
};

export let baseUrlRedirect = {
  baseUrl: `${url}`,
};

export const baseUrlforWebsocket = `${environmentVariableForWebsocket}/keibai-ws`;

export const tokenUrl = `${b.baseUrl}/token`;
export const sellPostUrl = `${b.baseUrl}/pb/product/upload`;
export const contactUsUrl = `${b.baseUrl}/pb/user/contactUs`;
export const advertiseUrl = `${b.baseUrl}/pb/publicize/adv/items`;
export const signupUrl = `${b.baseUrl}/signup`;
export const profileUrl = `${b.baseUrl}/user/profile`;
export const changePasswordUrl = `${b.baseUrl}/user/changePassword?`;
export const previewUrl = `${b.baseUrl}/auction/preview`;
export const auctionProductUrl = `${b.baseUrl}/product/auctionProducts`;
export const auctionProductMyBidsUrl = `${b.baseUrl}/product/myBids`;
export const tendorBidUrl = `${b.baseUrl}/tender/bid`;
export const auctionTendorProductUrl = `${b.baseUrl}/tender/tenderProducts`;
export const productUrl = `${b.baseUrl}/product`;
export const watchlistUrl = `${b.baseUrl}/product/watchlist`;
export const calendarEventUrl = `${b.baseUrl}/auction/calendarEvents`;
export const shippingAddressUrl = `${b.baseUrl}/user/upsertShippingAddress`;
export const bankDetailUrl = `${b.baseUrl}/user/upsertBankDetail`;
export const markAsDefaultBankDetailsUrl = `${b.baseUrl}/user/markAsDefault`;
export const markAsDefaultShippingAddresssUrl = `${b.baseUrl}/user/markAsDefaultShippingAddresss`;
export const deleteBankDetailsUrl = `${b.baseUrl}/user/deleteBankDetails`;
export const deleteShippingAddress = `${b.baseUrl}/user/deleteShippingAddress`;
export const changePlanUrl = `${b.baseUrl}/user/changePlan`;
export const changeDefaultPassword = `${b.baseUrl}/user/changeDefaultPassword`;
export const forgotPasswordUrl = `${b.baseUrl}/pb/user/forgotPassword`;
export const resetPasswordUrl = `${b.baseUrl}/pb/user/resetPassword`;
export const sendLoginOTP = `${b.baseUrl}/pb/user/sendLoginOTP`;
export const searchAuctionFilterUrl = `${b.baseUrl}/auction/search`;
export const invoiceUrl = `${b.baseUrl}/invoice/list`;
export const auctionWebsocketUrl = `${baseUrlforWebsocket}/auction`;
export const joinAuctionUrl = `${b.baseUrl}/auction`;
export const participateUrl = `${b.baseUrl}/auction/sendParticipationRequest`;
export const joinedAuctionsUrl = `${b.baseUrl}/auction/getJoinedAuctions`;
export const advertisementUrl = `${b.baseUrl}/advertise/list`;
export const downloadandChangeShippingAddressInvoiceUrl = `${b.baseUrl}/invoice`;
export const allAuctionsUrl = `${b.baseUrl}/pb/auction/auctions`;
export const currentTimeUrl = `${b.baseUrl}/user/currentTimeMillis`;
export const preferredlanguageUrl = `${b.baseUrl}/user/preferredLanguage`;
export const getHomeOngoingAuctionsUrl = `${b.baseUrl}/auction/getHomeOngoingAuctions`;
export const getAllJoinedAuctionsUrl = `${b.baseUrl}/auction/getAllJoinedAuctions`;
export const rateFromUSD2JPY = `${b.baseUrl}/currency/rateFromUSD2JPY`;
export const clockNotInSyncUrl = `${b.baseUrl}/notify/clockNotInSync?millis=`;
export const timeDifferenceUrl = `${b.baseUrl}/user/getTimeDifference?userTimezone=`;
export const kycFilledDataUrl = url;
export const sendInviteeParticipationRequest = `${b.baseUrl}/auction/sendInviteeParticipationRequest`;
export const getProductDetailUrl = `${b.baseUrl}/pb/product`;
export const productShareUrl = `${url}`;
export const getUserDetailPayment = `${b.baseUrl}/pb/payment`;
export const processPayment = `${b.baseUrl}/pb/payment/processPayment`;
export const userInvoiceDownload = `${b.baseUrl}/invoice/subscription_invoice`;
export const productDownload = `${b.baseUrl}/product/download/excel`;
export const updateProfileInfo = `${b.baseUrl}/user/upsertProfileInfo`;
export const getTimeZones = `${b.baseUrl}/user/timezones`;
export const productTextSearch = `${b.baseUrl}/product/textSearch`;
export const autoCompleteSearchProduct = `${b.baseUrl}/auction/search`;
export const postErrorLog = `${b.baseUrl}/pb/log`;
export const getCountry = `${b.baseUrl}/pb/country`;
export const getState = `${b.baseUrl}/pb/list/state`;
export const getCity = `${b.baseUrl}/pb/list/city`;
export const getCurrency = `${b.baseUrl}/pb/currency`;
export const getBidInfo = `${b.baseUrl}/tender/bid_info`;
export const getAuctionEndTimeUrl = `${b.baseUrl}/auction`;
export const getTimeZonePB = `${b.baseUrl}/pb/list/timezones`;
export const getKYCSubdomains = `${url}/admin/kyc/checkSubDomain`;

export const jewelleryProductUrl = `${b.baseUrl}/product/jewellery/jewelleryProducts`;
export const jewelleryProductViewUrl = `${b.baseUrl}/product/jewelleryProducts`;
export const searchJewelleryAuctionFilterUrl = `${b.baseUrl}/auction/search/jewellery`;
export const jewelleryProductDetailsUrl = `${b.baseUrl}/product/jewellery`;
export const upcomingProducts = `${b.baseUrl}/auction`;
export const auctionTendorMyBids = `${b.baseUrl}/tender/myBidProducts`;
export const getNotes = `${b.baseUrl}/product`;
export const getSupportApiData = `${b.baseUrl}/enquiry/list`;
export const enquiryPostdata = `${b.baseUrl}/enquiry/submit`;
export const enquiryCommentsdata = `${b.baseUrl}/enquiry/comment`;
export const getMyLatestBidsForUser = `${b.baseUrl}/tender/list/mybids`;
export const getInquiryApiData = `${b.baseUrl}/enquiry/getEnquiry`;
export const submitFreePersonalized = `${b.baseUrl}/pb/personalised/request/submit`;
//get pending product
export const negotiatependingProduct = `${b.baseUrl}/product/pending`;
export const negotiatependingProductPostAPI = `${b.baseUrl}/product/negotiate`;
export const negotiateAllpendingProduct = `${b.baseUrl}/product/pending/products`;
export const getLiveNotifications = `${b.baseUrl}/live_notifications/getLast24HrsLiveNotifications`;
export const getAllNotificationsInfo = `${b.baseUrl}/live_notifications/info`;
export const getLiveEralierNotifications = `${b.baseUrl}/live_notifications/getLastOneMonthLiveNotifications`;
export const getAllNotifications = `${b.baseUrl}/live_notifications/getAllNotifications`;
export const getRead = `${b.baseUrl}/live_notifications/read?id`;
export const getDelete = `${b.baseUrl}/live_notifications/delete?id`;
export const getInvoiceTracking = `${b.baseUrl}/shipping/tracking/detail`;
export const postShipingInformation = `${b.baseUrl}/shipping/upsertTrackingInformation`;
export const getShipingCompanyName = `${b.baseUrl}/shipping/companies`;
export const getMoreShipingAddress = `${b.baseUrl}/shipping/address/details`;
export const setShipingAddressAsDefault = `${b.baseUrl}/shipping/markAsDefault`;
export const deleteShippingDetails = `${b.baseUrl}/shipping/deleteShippingDetails`;
export const getMyWatchedProduct = `${b.baseUrl}/tender`;

//365 product Auctions
export const annualAuctionJoinedCall = `${b.baseUrl}/auction/Annual`;
export const annualAuctionProduct = `${b.baseUrl}/annual/annualAuctionProducts`;
export const getJoinedAnnualAuctions = `${b.baseUrl}/annual/getJoinedAnnualAuctions`;
export const annualBidUrl = `${b.baseUrl}/annual/bid`;
export const getAnnualBidInfo = `${b.baseUrl}/annual/bid_info`;
export const annualMyLatestBidsForUser = `${b.baseUrl}/annual/list/mybids`;
export const annualSoldProduct = `${b.baseUrl}/annual/soldProducts`;
export const annualSellerSoldProduct = `${b.baseUrl}/annual/sellerSoldProducts`;
export const annualMyBidProducts = `${b.baseUrl}/annual/myBidProducts`;
export const annualMyWatchedProducts = `${b.baseUrl}/annual`;
export const annualMyRecentBidsForUser = `${b.baseUrl}/annual/list/recentMybids`;
export const invoiceUsdUrlAnnual = `${b.baseUrl}/invoiceAnnual/list/international`;
export const downloadandInternationalAnnualInvoiceUrl = `${b.baseUrl}/invoiceAnnual/download/international_invoice?invoiceId=`;
export const sendInvoiceRequest = `${b.baseUrl}/invoiceAnnual/sendRequestInvoiceGeneration`;
export const performaInvoiceAPI = `${b.baseUrl}/invoiceAnnual/list/simplePerforma`;
export const performainternationalInvoice = `${b.baseUrl}/invoiceAnnual/list/internationalPerforma`;
export const downloadandInternationalInvoiceUrl = `${b.baseUrl}/invoice/international_invoice`;
export const shippingAnnualInvoice = `${b.baseUrl}/shippingAnnual/address/details`;
export const shippingAnnual = `${b.baseUrl}/shippingAnnual`;
export const setshippingAnnualAddressAsDefault = `${b.baseUrl}/shippingAnnual/markAsDefault`;
export const deleteAnnualShippingAddress = `${b.baseUrl}/shippingAnnual/deleteShippingDetails`;
export const annualTrackingApi = `${b.baseUrl}/shippingAnnual/tracking/detail`;
export const annualupsertTrackingApi = `${b.baseUrl}/shippingAnnual/upsertTrackingInformation`;
export const productInvoiceApi = `${b.baseUrl}/invoiceAnnual/list`;
export const internationalPerforma = `${b.baseUrl}/invoiceAnnual/download/international_invoice/performa?invoiceId=`;
export const simpleAnnualPerforma = `${b.baseUrl}/invoiceAnnual`;
export const productPublicApi = `${b.baseUrl}/pb/publicize/products`;
export const endedProductPublicApi = `${b.baseUrl}/pb/publicize/endedProduct`;
export const singleProductDetailApi = `${b.baseUrl}/product`;
export const mypageWatchlist = `${b.baseUrl}/product/myPage/watchlist`;
export const mypagesellApi = `${b.baseUrl}/product/myPage/products`;
export const mypageExportExcel = `${b.baseUrl}/product/myPage/export/excel`;
export const requestPaddleNumber = `${b.baseUrl}/user/requestPaddleNumber`;
export const preBiddingEndTime = `${b.baseUrl}/auction/preBiddingTime`;
export const annualBuybid = `${b.baseUrl}/annual/bid`;
export const awsGetUrl = `${b.baseUrl}/aws/getSignedURL`;
export const addInventory = `${b.baseUrl}/inventory/sell/data`;
export const templateDownload = `${b.baseUrl}/inventory/downloadExcels`