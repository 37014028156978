export default {
  common: {
    hi: "您好",
    menuText: "菜单",
    createNow: "现在创建",
    bid: "投标",
    message: "信息",
    liveAuc: "现场拍卖",
    prevAuc: "上次拍卖",
    liveFeatureAuc: 'Featured Products',
    startsin: "Starts in",
    endsin: 'Ends in',
    buyNow: "Buy Now",
    buyItNowPrice: "Buy It Now Price",
    auc: "拍卖",
    live: "现场",
    previous: "上一次",
    filSearch: "筛选您的搜索",
    newUserSignUp: "新用户？在此注册",
    h2l: "从高到低",
    l2h: "从低到高",
    filSrch: "筛选搜索",
    jwlry: "珠宝",
    diamond: "钻石",
    items: "项目",
    refine: "细化",
    reset: "重置",
    zipangText:
      "紫光阁拍卖是一个特占优势的B2B拍卖会，为已经过验证的国际企业机构提供服务",
    signIntoYourAcc: "登录您的账户",
    hiw: "如何运作",
    whyus: "为什么选择我们？",
    videoUrl: "https://youtu.be/aGfOPky_VrU",
    savedSuccessfully: "Saved Successfully",
    updatedSuccessfully: "Updated Successfully",
    deletedSuccessfully: "Deleted Successfully",
    videoUrlNew: "https://www.youtube.com/watch?v=5bXpZ2Wrs8k",
    logout: {
      logout: "登出",
    },
    notificaiton: {
      notification: "通知",
      new: "更新",
      showAll: "显示全部通知",
      all: "全部",
      removeFromThe: "你已从拍卖中被移除。",
      markAsRead: "标示为已读",
    },
    testimonials: {
      1: "My Auctions 是迄今为止我见过的最好的拍卖系统。我以前使用过其他拍卖行的网站，但你的网站与其他的比较起来大获全胜。它的运作百分百顺畅",
      2: "My Auctions是一个易于操作的销售和竞标网站。每个从业者都应该从这个网站吸取经验教训，并学习如何在网上进行拍卖。",
    },
    exploreAuctionsCards: {
      1: "紫光阁拍卖是日本钻石贸易商的B2B市场。它提供360度图片，动态出价竞价，以及每月的现场实体竞价拍卖",
      2: "#365天拍卖会让买家和卖家在任何时间和地点拍卖珠宝",
      3: "其他拍卖项目支持小型公司在国际市场上拍卖。 如果你是一所小型企业，请马上联系我们！",
    },
    whyUs: {
      1: {
        title: "全球推广",
        1: "我们在全球广泛推广日本的典当市场。",
        2: "我们提供5473家行业公司的网络",
        3: "您的产品达到90%的起拍率。",
        4: "我们承诺在全球范围建立您的本地业务。",
      },
      2: {
        title: "预算友好",
        1: "为您的二手珠宝获得最高价格",
        2: "3%的低卖家佣金费用和0.5%的小型企业支持活动费用。",
        3: "网上拍卖和人员的低价租金及聘用费。",
        4: "免费和自动的电子邮件提醒，传真，和客制化发票。",
      },
      3: {
        title: "数据安全",
        1: "我们保护并加密您所有的敏感信息。",
        2: "我们维护您确认的任何数据的准确性。",
        3: "我们进行数以百计的客戶身分審查，以核实你所有的投标人",
        4: "我们值得信赖的物流团队保证零诈骗和零欺诈。",
      },
    },
    sellPages: {
      success: "请安心等待! 我们的专家正在工作处理中。",
      title: "出售你的钻石",
      heading: "得到你的快速",
      heading1: " 和准确的定价！",
      subheading:
        "我的拍卖会是一个值得信赖的商业伙伴。我们将与您合作，接触5000多个了解珠宝价值的外国客户。我们承诺，您将产生可观的收益。",
      areYouBuss: "你是一个企业吗？",
      visitSell: "访问卖家门户网站",
      or: "或",
      desOfStone: "石头的描述",
      prodDesc: "产品描述，如果有的话",
      setInJewel: "镶嵌在珠宝中",
      uploadPhoto: "上传照片",
      uploadCert: "上传证书",
      bulkUpload: "批量上传",
      companyName: "公司名称",
      enterValue: "日元（输入值）",
      estimation: "有来自其他的估计",
      select: "请选择",
      click: "点击这里",
      note: "注意",
      options: {
        1: "我将到你的办公室进行估价。",
        2: "给我寄估价资料邮包",
        3: "我想把我的钻石进行CGL分级（申请优惠券）。",
        note: "我们将承担所有的运输费用",
        note2: "在我们完成交易的情况下，应要求提供一份身份证明。",
      },
    },
    hero: {
      0: {
        heading1: "您的合作伙伴",
        heading2: "销售和购买",
        subHeading: "我们是日本发展最快的在线拍卖",
      },
      1: {
        heading1: "日本第一拍卖会 ",
        heading2: "二手珠宝的市场",
        subHeading: "以最低的佣金率赚取高额收入",
      },
      2: {
        heading1: "进入日本的",
        heading2: "指尖上的典当市场",
        subHeading: "发现只在日本销售的顶级质量的二手珠宝",
      },
      3: {
        heading1: "获得你的个性化",
        heading2: "拍卖网站一目了然",
        subHeading: "我们的合资格专家全天候支持您的业务",
      },
      4: {
        heading1: `“365 天促销活动”推出“立即购买”功能！`,
        heading2: "拍卖新方式",
        subHeading: "立即购买所需商品",
      }
    },
    header: {
      logoText: "我的拍卖品",
      buttons: {
        addAsFriend: "加为好友",
        login: "登入",
        loginNew: "登入",
        register: "注册",
        aboutUs: "关于我们",
        sellYDiamonds: "出售你的钻石",
        buyDiamonds: "购买钻石",
        buy: "买",
        sell: "卖",
        ea: "探索拍卖",
      },
      menu: {
        jpa: "紫光阁拍卖",
        oa: "其他拍卖会",
        "365a": "#365天拍卖会",
        ma: "管理你的拍卖",
        sd: "出售钻石",
      },
    },
    subHeader: {
      logoText: "我的拍卖",
      buttons: {
        whyUs: "为什么选择我们",
        howItWorks: "如何运作",
        media: "媒体",
        aboutUs: "关于我们",
        contactUs: "联系我们",
        testimonials: "客户评价",
        team: "团队",
        incubator: "培育器",
      },
    },
    loader: {
      loading: "加载中..",
      comingSoon: "即将到来",
    },
    languages: {
      en: "EN",
      jp: "JP",
      ch: "CH",
    },
    headerMenu: {
      expOptions: "探索拍卖",
    },
    button: {
      signIn: "登入",
      signInAsAuction: "作为拍卖师登录",
      createAccount: "创建账户",
      cancel: "取消",
      cancel_small: "取消",
      save: "储存",
      changePassword: "更改密码",
      changePlan: "改变计划",
      ok: "确定",
      filter: "筛选",
      search: "搜索",
      searchRemark: "备注搜索",
      reset: "重置",
      note: "备注",
      preBid: "预中标",
      cancelPreBid: "取消预投标",
      participate: "参加",
      join: "加入",
      advanceSearch: "高级搜索",
      basicSearch: "基本搜索",
      otpSignIn: "使用OTP登录",
      passwordSignIn: "使用密码登录",
      resendOtp: "重新发送OTP",
      resendOtpText: "重新发送",
      resendOtpMinutes: "分钟",
      resendOtpSeconds: "秒",
      seconds: "秒",
      verify: "核实",
      savePassword: "设置密码",
      savePasswordText: "设置你的用户账户密码，以继续进入我的拍卖会",
    },
    menu: {
      home: "主页",
      preview: "预览",
      auction: "拍卖",
      watchlist: "观察清单",
      invoice: "发票",
      profile: "简介",
      inquiry: "支持",
      pendingProduct: "待定产品",
      productAuction: "365天拍卖",
      mypage: "MY-PAGE",
    },
    footer: {
      copyrightFooter: "保留所有权利。 ",
      company: "MY AUCTIONS",
      copyright: "2020-21版权所有。",
      officeNumber: "O: +81358161188",
      faxNumber: "传真",
      officeAddressOld:
        "201 First Building, 3-10-1 Ueno Taitou Ku Tokyo 1100005",
      officeAddress: "4 Chome-10-7 Taito, Taito City, Tokyo 110-0016",
      emailId: "zipangauctions@gmail.com",
      hokuyu: {
        company: "MY AUCTIONS",
        copyright: "2020-21 保留所有权利。",
        officeNumber: "O: 09043537782",
        faxNumber: "传真",
        officeAddress: "2-7-6 , Shinjuku, Shinjuku Ward, Tokyo Japan",
        emailId: "koakuma2001@docomo.ne.jp",
      },
      zipangcompany: "Zipang Auctions HK Ltd. ",
      zipangofficeAddress: "Room 27, Workshop No. 20-24 2nd Floor",
      zipangofficeAddresssecond: "Block B Focal Industrial Centre, 21 Man Lok Street",
      zipangofficeAddressthird:'Hung Hom, Kowloon, Hong Kong',
      zipangofficeNumber:'O: +852 64203650',
      zipangofficeDPMsNumber:'DPMS No. : B-B-24-03-06147'
    },
    subdomainFooter: {
      emailId: "mjdiamondsco@gmail.com",
      company: "由MY AUCTIONS提供支持",
      hokuyu: {
        company: "株式会社北友",
        officeNumber: "09043537782",
        officeAddress: "2-7-6 , Shinjuku, Shinjuku Ward, Tokyo Japan",
        emailId: "koakuma2001@docomo.ne.jp",
      },
    },
    noMoreResultsToShow: "没有更多的结果可以显示",
    socialMedia: {
      facebook: "Facebook",
      pinterest: "Pinterest",
      twitter: "Twitter",
    },
    colorFilter: {
      all: "全部",
      pink: "粉红色(售出)",
      orange: "橙色(持有)",
      white: "白色(未售出)",
    },
    landingPage: {
      heading: "世界上第一个拍卖租赁网站",
      newHeading: "所有拍卖工具在一个门户网站",
      category: {
        home: "主页",
        diamonds: "钻石",
        bags: "包",
        accessories: "配件",
        watch: "手表",
        shoes: "鞋",
        jewellery: "珠宝首饰",
        septTitle: '三月拍卖'
      },
      // text: '拥有一个在线拍卖从未如此简单。你可以租用 "My Auction "为你的产品组织拍卖。我们汇集了世界一流的电子商务技术的力量在您的指尖，简单易用，促进您的业务成功。',
      text: "拥有一个在线拍卖从未如此简单。我们汇集了世界一流的电子商务技术的力量在您的指尖，简单易用，促进您的业务成功。",
      whyus: "为什么选择我们",
      becomeaMember: "成为会员",
      freeTrial: "14天免费试用",
      pointers: {
        firstOption: "隐私得到保证",
        secondOption: "最小风险",
        thirdOption: "最经济实惠的",
        fourthOption: "全球推广",
      },
      keyPointers: {
        firstPointer: {
          firstOption:
            "我们努力保护您的个人信息，并保持您所确认的信息的准确性。",
          secondOption:
            "当我们在互联网上传输所有敏感的个人信息时，我们对这些信息进行加密",
        },
        secondPointer: {
          firstOption: "所有的投标人都是经过交叉验证和客戶身分審查后參與的。",
        },
        thirdPointer: {
          firstOption: "人员聘用成本降至四分之一",
          secondOption: "免费自动提醒邮件和传真",
          thirdOption: "拍卖场地租用费减少至五分之一",
          fourthOption: "免费自动生成客制化发票",
        },
        fourthPointer: {
          firstOption: "我们将您的产品展示给我们成千上万的国际客户。",
          secondOption: "不需要外语能力 ",
        },
      },
      howItWorks: "它是如何运作的",
      list: {
        noOfProducts: "产品编号",
        preview: "预览日期(UTC)",
        auction: "拍卖日期(UTC)",
        participate: "参与",
      },
      floatIcon: "成为会员",
      banner: {
        first: {
          firstText: "与我们一起在线拍卖",
          secondText: "每年只需99美元",
        },
        second: {
          firstText: "通过邀请函与我们一起投标",
          secondText: "每年支付0美元",
          thirdText: "没有邀请码？成为投标人只需每年支付99美元",
        },
        third: {
          firstText: "同时成为拍卖组织者和竞标者",
          secondText: "只需每年支付149美元",
        },
        fourth: {
          firstText: "寻找一个空间来拍卖你的产品",
          secondText: "在'My Auction'上租用它",
          thirdText: "向右滑动以查看价格",
        },
      },
      whyMyAuction: {
        heading: "为什么是我们",
        firstLine: "在旧时代",
        secondLine: "现在",
      },
      testMoniels: {
        heading: "媒体",
        desFrist: "昨今のオークションで最も公平性なオークションです",
        desSecond: "日本の中で最も使い易くわかりやすいシステムです",
        desThird: "素晴らしい売買システム!",
        desFourth: "即座に対応してくれるカスタマーサポート",
        japanPrecious: "日本珍爱之夏2021",
        jewelristVol157: "Jewelrist 2021 Vol.157",
        jewelristVol158: "Jewelrist 2021 Vol.158",
        nominee: "百大科技创新者和影响者",
      },
    },
    login: {
      title: "登入",
      validation: {
        invalidEmailorPassword: "无效的证书",
        newinvalidEmailorPassword: "电子邮件或密码不被认可或不正确",
        userNotRegistered: "用户未注册",
        restrictedUserLine1:
          "我们已限制您访问 MyAuctions.jp 请联系 MyAuctions 或发送邮件至 contact@myauctions.jp",
        registeredAsAuctioneer: "此ID只注册为拍卖师",
      },
      labels: {
        email: "电子邮件",
        password: "密码",
      },
      forgotPassword: "忘记密码",
      dontHaveAccount: "没有账户",
      registerNow: "现在注册",
    },
    register: {
      title: "注册",
      occupation: {
        auctioneer: "拍卖者",
        bidder: "竞拍者",
        auctioneerAndBidder: "拍卖人和竞标人",
      },
      labels: {
        firstName: "名",
        lastName: "姓",
        email: "电子邮件",
        company: "公司",
        occupation: "职业",
        address: "地址",
        city: "城市",
        country: "国家",
        state: "州",
        phone: "电话",
        invitationCode: "邀请码",
      },
      placeholder: {
        firstName: "eg- 太郎",
        lastName: "eg- 山田",
        email: "eg- Taroyamada@abc.com",
        company: "eg- ABC Co. Ltd.",
        address: "eg- 上野，1-1-1大厦名称",
        city: "例如- 涩谷",
        country: "eg- 日本",
        state: "例如- 东京",
        phone: "eg- 1234567809",
        invitationCode: "eg- 123456",
        regexphone: "eg- 1234567809",
      },
      iAgree: "我同意",
      robotText: "我不是机",
      buttonText: "创建你的账户",
      termsandCondition: "服务条款",
      and: "和",
      privacyPolicy: "隐私政策",
      alreadyAccount: "已经有一个账户？",
      login: "登入",
      errorMessage: {
        onfirstName: "请输入正确的名字。",
        onlastName: "请输入正确的姓氏",
        onCheckTerms: "请同意 服务条款.",
        onMobileNumber: "请输入正确的手机号码",
        onEmail: "请输入正确的电子邮件地址。",
        newonEmail: "您的电子邮件不被认可。",
        emptyEmail: "请输入电子邮件地址。",
        occupationNotSelect: "请选择职业。",
        alreadyRegistered: " 已经有用户注册了该邮箱。",
        pendingRequest: "已经有一个用户在等待审核",
        invitationCode: "请在验证码中使用数字字符",
        phoneNumber: "请输入有效的电话号码",
        notSeletBidder: "请选择职业为 '投标人 '以使用邀请码进行注册",
        profileVerification:
          "您的个人资料仍在核实中。一旦您的资料被核实，我们将向您注册的电子邮件地址发送一封电子邮件",
        correctInvitationCode: "邀请码不正确",
      },
      registerModal:
        "我们已经收到您的注册请求。我们的团队将很快通过电子邮件与您联系。",
      subdoaminRegisterModal: " 您没有在My Auctions注册。",
      secondSubdoaminRegisterModal: "请订阅以获得完整的访问权。",
    },
    profile: {
      title: {
        personalDetails: "个人详细资料",
        bankDetails: "银行详情",
        shippingAddress: "送货地址",
      },
      legends: {
        email: "电子邮件",
        companyName: "公司名称",
        contactNo: "联系号码",
        modeOfContact: "联系方式",
        bankName: "银行名称",
        accountHolderName: "A/C持有人姓名",
        accountNo: "A/C号码",
        ifscCode: "IFSC代码",
        bankAddress: "银行地址",
        timezone: "时区",
        companyAddress: "公司地址",
        guarantorName: "担保人姓名",
        guarantorAddress: "担保人地址",
        referenceName: "参考名称",
        referenceAddress: "推荐人地址",
        editProfile: "编辑资料",
        refbankName: "银行名称",
        refaccountHolderName: "账户持有人姓名",
        refaccountNo: "账户号码",
        refifscCode: "Swift No",
        refbankAddress: "银行地址",
        validation: {
          enterCompanyName: "请输入公司名称",
          enterCompanyAddress: "请输入公司地址",
          enterReferenceName: "请输入参考名称",
          enterReferenceAddress: "请输入参考地址",
          enterContactNo: "请输入联系号码",
          enterContactNoVal: "请输入有效的联系号码",
          enterContactNoValregex: "请输入有效的联系号码，例如-+1234567809",
          contactNoMustBe: "联系号码必须是数字",
          enterTimeZone: "请选择时区",
          enterModeOfContact: "请选择联系方式",
          enterCountry: "请选择国家",
          enterCurrency: "请选择货币",
          enterContactNoValBtw: "请输入有效的8-15位的联系号码",
        },
      },
      shippingAddress: {
        title: "送货地址",
        labels: {
          blockNo: "区号",
          streetName: "街道名称",
          city: "城市",
          state: "州",
          country: "国家",
          company: "Company Name",
          address: "Address",
          phoneNo: "Phone No.",
          pinCode: "PinCode",
        },
      },
      bankDetails: {
        title: "银行详情",
        labels: {
          holderName: "银行持有人姓名",
          acNumber: "A/C号码",
          ifscCode: "IFSC代码",
          bankName: "银行名称",
          bankAddress: "银行地址",
        },
      },
      changePassword: {
        title: "更改密码",
        labels: {
          oldPassword: "旧密码",
          newPassword: "新密码",
          confirmPassword: "确认密码",
        },
        validation: {
          enterOldPassword: "请输入旧密码",
          enterNewPassword: "请输入新密码",
          enterConfirmPassword: "请输入确认密码",
          incorrectOldPassword: "旧密码不正确",
        },
      },
      forgotPassword: {
        title: "忘记密码",
        labels: {
          otp: "OTP",
          newPassword: "新密码",
          confirmPassword: "确认密码",
          email: "输入你的注册邮箱地址",
        },
        button: {
          update: "重置密码",
          continue: "发送OTP",
        },
        validation: {
          invalidOTP: "无效的OTP！",
          newinvalidOTP: "你输入的代码不正确",
          correctOTP: "请输入正确的OTP",
          enterPassword: "请输入密码",
          notRegisteredEmail: "不是注册的电子邮件地址",
        },
        note: {
          otpNote: "OTP已经发送到您的电子邮箱和联系电话。",
          newOtpNote: "OTP已发送至您的电子邮箱和联系电话。",
          emailNote:
            "在下面输入你的电子邮件地址，我们将发送一个OTP来重置你的密码。",
        },
        onSuccessful: {
          passwordChange: "密码成功更改。",
        },
      },
      changePlan: {
        receiveYourRequest: "我们已经收到你的请求，",
        changeThePlan: "改变计划",
        getInTouch: "我们的团队将很快与您取得联系。",
      },
      toast: {
        passwordUpdate: "密码更新成功",
      },
      validation: {
        newAndConfirmPasswordNotMatch: "新密码与已确认密码不一致。",
        newAndOldPasswordNotMatch: "新密码应与旧密码不同。",
      },
      optionMenu: {
        markAsDefault: "标记为默认",
        delete: "删除",
      },
      addMore: "添加更多+",
      userType: "用户类型 ",
      startDate: "开始日期 ",
      endDate: "结束日期 ",
      downloadInvoice: "下载发票",
    },
    preview: {
      products: "产品",
      bidButton: '点击此处进入',
      noProductFound: "没有找到产品",
      noAuctionFound: "没有拍卖品",
      addedToWatchList: "观察",
      addedToWatchMsg: "成功添加到观察列表",
      addWatchList: "取消观察",
      carousalTitle: "类别",
      price: "价格",
      pieces: "Pcs.",
      ct: "CT",
      pol: "POL",
      sym: "SYM",
      at: "@",
      linkCopied: "复制的链接",
      showAllProduct: "显示所有产品",
      tendor: {
        live: "活",
        popcornBidding: "动态出价。",
        allProducts: "所有产品",
        myBids: "我的出价",
        timeLeft: "剩余时间 :",
        bulkBid: "批量出价",
      },
      productsList: {
        startingPrice: "起始价",
        ro: "Rap 折扣",
        preBiddingPrice: "投标前价格",
        serialNo: "S号",
        pct: "Pct.",
        marqueeExchangeRate: "发票内的汇率将与预览的第一天相同=（1美元= ",
        yen: "日元",
      },
      filterName: {
        shape: "形状",
        color: "颜色",
        clarity: "净度",
        grading: "等级",
        price: "价格",
        weight: "重量",
        fancyColor: "花色",
        cutPolishSymmetry: "切割抛光对称性",
        cut: "切工",
        startingPrice: "起始价格",
        jewellery: "珠宝类型",
        jewelleryCondition: "珠宝条件",
      },
      productDetail: {
        itemDescription: "项目描述",
        share: "分享",
        copy: "复制",
        viewFullScreen: "查看全屏",
        shareTooltip:
          "用户可以与他们的同事共享此产品详细信息页面。起始价格 Pct.、每克拉的预出价、起始价格的数量、每克拉的价格和 rap % 将不会在共享链接中显示。",
        watchTooltip:
          "当您标记为最爱的产品出现在现场拍卖上，您将在拍卖十个产品之前收到一封电子邮件提醒。",
        jewelleryShareTooltip:
          "用户可以与他们的同事分享这个产品的详细页面。起拍价，预出价，价格在共享链接中不可见。",
        tendorshareTooltip:
          "用户可以与他们的同事分享这个产品的详细页面。起价，起价金额，价格将在共享链接中不可见。",
        tendorDiamondshareTooltip:
          "用户可以与他们的同事分享这个产品的详细页面。起价百分比、起价金额、每克拉的的价格和Rap %在共享链接中是不可见的。",
      },
      preBidding: {
        jpy: "000JPY",
        dollar: "0$",
        jpyJewellery: "JPY",
        preBidding: "每克拉起拍价",
        enterAmount: "输入金额",
        jwlEnterAmount: "Enter amount",
        validationOnCorrectAmount: "预出价金额应大于产品的起价",
        placeholderText: "输入预竞价的金额",
        preBidPopUpMessage: "您确定要用预出价吗",
        JewPreBidding: "预出价",
      },
      notes: {
        notes: "注释",
        save: "保存",
        enterNote: "输入备注",
        placeholderText: "为产品添加注释",
        notesValidation: "字符应少于200个字符",
      },
      perItemDescription: {
        serialNo: "序列号",
        startingPrice: "起拍价百分比",
        auctionStartDate: "拍卖开始日期",
        startDate: "开始日期",
        itemCode: "项目代码",
        carat: "Cts.",
        color: "颜色",
        clarity: "净度",
        cutGrade: "切割等级",
        ex: "3EX",
        hnc: "HnC",
        flour: "荧光",
        lengthWidthHeight: "长x宽x高",
        polish: "波兰语",
        symmetry: "对称性",
        preBidAmount: "每克拉预标价",
        shape: "形状",
        origin: "产地",
        lab: "实验室",
        certificate: " 证书#",
        fancyIntensity: "彩色强度",
        fancyColor: "彩色",
        startingPricePerCarat: "每克拉起价",
        sellingPricePerCarat: "每克拉售价",
        amountOfStartingPrice: "起价金额",
        pricePerCarat: "每克拉价格（美元）",
        rap: "Rap%",
        rapPrice: "Rap价格",
        exchangeRate: "汇率",
        remarks: "备注",
        signInToView: "登录后查看",
        noProductMessage: " 该产品已被删除。",
        sieve: "筛子",
        noOfPieces: "件数",
        piecesPCT: "件数PCt",
        pricePctUsd: "价格PCT（USD）",
        totalCts: "总CT数",
        avgSize: "平均尺寸",
        totalAmount: "起价金额",
        productDescription: "产品描述",
        jewelleryStartingPrice: "珠宝起价",
        jewelleryType: "珠宝类型",
        jewelleryCondition: "珠宝条件",
        jewelryPricePctUsd: "价格PCT（美元）",
      },
      tabLayout: {
        diamond: "钻石",
        parcelItem: "同包项目",
      },
      filters: {
        cutFilter: {
          cut: "切工",
          polish: "抛光",
          symmetry: "对称性",
        },
        price: {
          ct: "/CT",
          total: "总数",
        },
        fancyColor: {
          intensity: "强度",
          overTone: "色调差/色调前后缀",
          color: "颜色",
        },
        from: "从",
        to: "到",
      },
      modal: {
        participateDontHaveCode: "没有邀请码？ ",
        forparticipation: "只要点击参与，我们就会与你联系。",
        placeholderText: "邀请码",
        invitationCodeText: "请输入邀请码以参加拍卖。",
        participateTextInvitee:
          "您的账户未启用，无法进入其他拍卖会。我们的团队将很快与您联系。",
        participateText:
          "我们已经收到您的参与请求。我们的团队将很快通过电子邮件与您联系。",
        disableParticipation: "您可以在拍卖开始前1小时参与。",
      },
      search: {
        keyword: "关键词",
      },
      pagination: {
        showing: "展示",
        page: "页面",
        of: "的",
        results: "结果",
        lowestJpy: "最低的日元",
        highestJpy: "最高日圆",
        lowestCarat: "最低克拉",
        highestCarat: "最高克拉",
        itemsPerPage: "每页项目",
        carat: "克拉",
        jpy: "日元",
        myBids: "我的出价",
        lowestBids: "最低的出价",
        highestBids: "最高的出价",
        export: "出口",
        inquiry: "查询",
        select: "---选择---",
      },
    },
    home: {
      videoHeading: "拍卖如何进行",
      auctionHeading: "正在进行的拍卖",
      calendar: "日程表",
      advertisementHeading: "广告",
      upcomingAuctions: "Upcoming Auctions",
      requestAppointment: " Request Appointment",
      legend: {
        legend: "说明",
        preview: "预览",
        auction: "拍卖",
        noAuction: "无拍卖",
        appointment: "Appointment",
      },
      auctionDetails: {
        serialNo: "序列号 -",
        timeLeft: "剩余时间",
        startingPricePerCarat: "起拍价",
        notes: "说明",
        preBiddingPrice: "竞价前价格",
        rapnetPrice: "Rapnet价格",
        rapnetPercentage: "Rapnet百分比",
        exchangeRate: "汇率",
        remarks: "备注",
        rapOff: "Rap 折扣",
      },
      modal: {
        soldOut: "售出的物品",
        at: "@",
        congratulations: "祝贺你！",
        mazal: "成交!!",
        text: "你已经赢得了 '的投标",
      },
      heading: {
        auction: "拍卖",
        preview: "预览",
        fullDay: "全天",
      },
      list: {
        starting: "开始",
        price: "价格",
        participate: "参与",
      },
      noProductsAvailable: "没有适用的产品",
      downloadPdf: "下载拍卖如何运作PDF",
      previweNotStarted: "预览未开始",
    },
    invoice: {
      header:
        "请注意，下面的发票是仅供参考的。如果您是日本以外的买家；请使用海关清关发票进行付款和快递服务。",
      noInvoice: "没有发票",
      buttons: {
        shippingAddress: "运费添加。",
        download: "下载",
        issueInvoice: "生成进口发票",
        trackingButton: "跟踪",
        invoiceButton: "发票",
      },
      heading: {
        date: "日期",
        totalInvoice: "发票总额",
        totalAmountToPay: "要支付的总金额",
        totalAmountToReceive: "应收总金额",
      },
      filter: {
        search: "搜索",
        startDate: "选择开始日期",
        endDate: "选择结束日期",
      },
      validation: {
        selectAddress: {
          firstLine: "请选择一个您希望发货的地址。",
          secondLine: "如果没有选择地址，那么将在您的默认送货地址进行交货",
          thirdLine: "注意 - 你可以通过个人资料页面添加或更改送货地址",
        },
        noShippingAddressFound:
          "没有找到送货地址。你可以通过个人资料页添加它。",
      },
      tracking: {
        recipientLable: "发行买方发票",
        invoiceButton: {
          trackYourPackage: "追踪你的包裹",
          saveDetails: "保存详细信息",
        },
        trackingForm: {
          shippingCompanyName: "航运公司",
          awbTrackingNo: "输入AWB跟踪号码",
          detailsSubmit: "您的信息已成功提交",
        },
        buyerDetails: {
          companyName: "名称",
          country: "国家",
          deliveryAddress: "送货地址",
          RecipientName: "收件人",
        },
        validation: {
          companyNameValidation: "请选择公司名称",
          awbNumberValidation: "请输入你的AWB追踪号码",
        },
      },
    },
    auction: {
      auctionEnded: {
        firstLine: "谢谢你 ",
        secondLine: "参加拍卖！！",
        thirdLine: "很快就会见到你。",
      },
      auctionHold: {
        firstLine: "您的产品被搁置。",
        secondLine: "我们将在2个工作日内通过电子邮件让您了解产品的销售情况",
      },
      auctionPaused: {
        firstLine: "拍卖暂停了。",
        secondLine: "由于技术原因，拍卖暂停了",
        thirdLine: "请等待，我们将恢复。",
      },
      auctionAboutToStart: {
        aboutToStart: "拍卖即将开始",
      },
      auctionCancelled: {
        firstLine: "由于系统中的一些技术故障，我们已经结束了拍卖。",
        secondLine: "对造成的不便表示歉意。",
      },
      notAbleToStart: "无法开始",
      technicalGlitch: {
        firstLine: "由于一些技术故障，拍卖无法开始。",
        secondLine: "请联系各自的拍卖商了解最新情况。",
      },
      connectionLost: {
        firstLine: "连接丢失",
        secondLine: "请刷新页面或检查互联网连接",
      },
      automaticClock: {
        firstLine: "您的系统时区不在自动模式下。",
        secondsLine:
          "你可能在网站上面临几秒钟的延迟。为了避免这种情况，请将系统时区设置为自动模式。",
      },
      sellerMessage: {
        firstLine: "投标受限。",
        secondLine: "您是这个产品的卖家。 ",
      },
      pasuedModal: {
        headerfirstLineFirst: "注意 ",
        headerfirstLineSecond: "在产品编号之后",
        headerSecondLine: "放松一下!",
        biddingCont: "投标将继续",
      },
      tenderExtendedTime: {
        firstLine: "还没有结束",
        secondLine: "在过去15分钟内出价的产品已被",
        thirdLine: "给予30分钟的延长时间",
        lastLine: "继续出价！！",
      },
    },
    watchList: {
      watchlistEmpty: "观察清单是空的",
    },
    allValidations: {
      participate: {
        noAuctionFound: "没有发现拍卖",
        mailSentFailed: "邮件发送失败",
        alreadySentParticipationMail: "已发送参与邮件",
        alreadySentInviationMail: "邀请函已经发送",
      },
      join: {
        invalidCodeorMail: "无效代码或未收到邮件",
        invalidCode: "无效代码",
        enterInvitationCode: "请输入邀请码",
      },
      bank: {
        accountHolderName: "请输入账户持有人姓名",
        accountNumber: "请输入账号",
        ifscCode: "请输入ifsc代码",
        bankName: "请输入银行名称",
        address: "请输入地址",
      },
      shipping: {
        blockNo: "请输入街区编号",
        address: "请输入地址",
        streetName: "请输入街道名称",
        city: "请输入城市",
        state: "请输入州",
        country: "请输入国家",
        company: "请输入公司",
      },
      emptyField: "请填写必填项",
      emptyFields: "请填写必填字段",
    },
    timeOut: {
      sorry: "对不起",
      somethingWrong: "出错了",
      tryAgain: "请再试一次",
    },
    pageNotFound: {
      oops: "哎呀！",
      sorryPageNotFound: "对不起，找不到你要找的页面",
      goToHome: "转到主页",
    },
    paymentAgreement: {
      fourModal: {
        paymentAgreementInEn: `All matters in question shall be investigated by Bidder prior to
              purchase agreement, after which the transaction is final and
              irreversible, except in cases such as treatment in the diamond
              that has not been disclosed or doubts regarding the Origin (
              Natural / Laboratory grown / Coating etc. ) of the diamond.`,
        paymentAgreementInJp: `すべての懸念事項は、購入契約の前に入札者が調査するものとします。その後、取引は最終的で不可逆的に成立するものとします。ただし、開示されていないダイヤモンドの処理（人工ダイヤ/コーティングなど）に関する疑いがある場合を除きます
              。`,
        paymentAgreementInZh: `投标人应在达成购买协议之前看清所有相关事宜，交易后不可撤销，除未披露钻石的处理方式或对原产地（天然/实验室培育/涂层等）有疑问
              的钻石。`,
      },
      secondModal: {
        paymentAgreementInEn: `Payment for the generated invoice is due within 3 business days of
              notice via bank Wire. Failure to submit payment by the due date
              will result in a late fee of 1% and could result in additional
              handling fees.`,
        paymentAgreementInJp: `オークション終了後に作成された請求書の支払い期限は、
              銀行振込による通知から 3
              営業日以内です。期日までに支払いを提出しないと、1%
              の遅延料金が発生し、追加の手数料が発生する可能性があります。`,
        paymentAgreementInZh: `发付款应在收到发票通知后 3
              个工作日内通过银行电汇支付。未能在到期日之前付款将导致 1%
              的罚金，并可能导致额外的手续费。`,
      },
      thirdModal: {
        paymentAgreementInEn: `Freight charges, custom charges and any other additional costs are
              born by bidder. Any discrepancies between the merchandise listed
              on the invoice and the actual merchandise received must be
              reported to within 24 hours of receipt.`,
        paymentAgreementInJp: `実際に御受け取りいただいた商品が請求書に記載の商品と異なる場合にはお受け取りから24時間以内に必ずご連絡ください。
        `,
        paymentAgreementInZh: `运费、海关费用和任何其他额外费用由投标人承担。发票上列出的商品与收到的实际商品之间的任何差异必须在收到后
              24 小时内报告。`,
      },
      firstModal: {
        paymentAgreementInEn: `Please be advised that a buyer commission of 3% will be applicable on the final purchase invoice.`,
        paymentAgreementInJp: `最終的な購入請求書には3％のバイヤー手数料がかかることをご了承ください。`,
        paymentAgreementInZh: `请注意，最终购买发票将收取 3% 的买家佣金。`,
      },
    },
    ongoing: "正在进行",
    paused: "暂停的",
    break: "中断",
    willResume: "将恢复中",
    abouttoStart: "即将开始",
    invalidRequest: "无效的请求。.",
    restrictToJoinAuction: "要参加拍卖，请将你的机器的时钟设置为自动模式。",
    warning: {
      firstLine: "警告！！",
      secondLine: "请将你的机器的时钟设置为自动模式。",
    },
    somethingWentWrong: "出了点差错。请等待一段时间后再试。",
    kycFrom: {
      kycFromHeading: "KYC表格",
      name: "姓名",
      fName: "名",
      lName: "姓",
      line: "行",
      whatsup: "Whatsapp",
      weChat: "微信",
      sms: "短信",
      facebook: "FB",
      swiftNo: "Swift No",
      modeOfCommunication: "通讯方式",
      japan: "日本",
      other: "其他",
      knowKobustuLicense: "你是否有工布图许可证？",
      kobustuLicenceQuestion:
        "你是否有工布津许可证/或在日本有工布津许可证的联盟公司？ ",
      guaranterCompany: "担保人",
      companyDetail: "公司详情",
      contactPerson: "联系人",
      attachFiles: "附上文件",
      kobustuLicense: "Kobustu执照",
      uploadFiles: "上传文件",
      indenfyCertificate: "带照片的身份证明（密码",
      passbookCopy: "存折副本",
      tokiboTohon: "Tokibo Tohon",
      shippingAddressCapitalize: "送货地址",
      billingAddress: "账单地址",
      submit: "提交",
      yes: "是",
      no: "不",
      bankDetails: "银行资料",
      blockNumber: "街区号码",
      streetName: "街道名称",
      city: "城市",
      state: "州",
      subDomain: "子域",
      samePersonalDetail: " 与个人资料相同",
      sameBillingAddress: " 与帐单地址相同",
      preferredLanuage: "首选语言",
      preferredCurrency: "首选货币",
      currency: "货币",
      companyLogo: "公司标志",
      document: "文件",
      shouldBeLess: "应小于10MB",
      natureofBusiness: "业务性质",
      kycModal: {
        firstLine: "谢谢你提交表格",
        secondLine: "我们的团队将很快给你答复",
      },
      businessRegistration: {
        headingName: "业务细节",
        heading:
          "商业注册号码/公司证书或许可证或证据，这使你有权经营这个特定的业务",
        dateOfIncorporation: "成立日期",
        iCNumber: "公司注册证书编号",
      },
      saveAsDraft: "保存为草稿",
      companyDoc: "公司注册文件 ",
      bankDoc: "银行文件（任何由银行出具的证明所输入的银行信息的文件",
      bankDocHeading: "银行文件",

      errorMessage: {
        onFName: "请填写名",
        onLName: "请填姓氏",
        onCompanyName: "请填写公司名称",
        onShippingAdd: "请填写送货地址",
        onBillingAddress: "请填写账单地址",
        onBankName: "请填写银行名称",
        onBankAddress: "请填写银行地址",
        onAccHolderName: "请填写账户持有人姓名",
        onSwiftNo: "请填写swift号码",
        onAccountNo: "请填写账户号码",
        onCountry: "请填写国家",
        onKobustuLic: "您是否有kobustu许可证？",
        onPreferredLanguage: "请选择首选语言",
        onPreferredCurrency: "请选择首选货币",
        onModeOfComm: "请选择通信方式",
        onContactPerson: "请填写联系人",
        onKobustuLicense: "请上载小客车牌照",
        onTokiboTohon: "请上传公司注册证书",
        onPassbookCopy: "请上传存折副本",
        onIdentityCertificate: "请上传身份证明",
        onCompanyLogo: "请上传公司标志",
        onSelectKobustuLicense: "你是否有古物商业执照？",
        onGuarantorCompanyName: "请填写担保人公司名称",
        onGuarantorContactPerson: "请填写担保人联系人",
        onGuarantorEmail: "请输入正确的担保人电子邮件地址",
        onGuarantorContactNumber: "请输入有效的担保人联系号码",
        onGuarantorMobileNumber: "请输入正确的担保人联系电话",
        onGuarantorBillingAddress: "请填写担保人的账单地址",
        onGuarantorShippingAdd: "请填写担保人送货地址",
        onGuarantorCountry: "请填写担保人所在国家",
        onGuarantorAccHolderName: "请填写担保人账户持有人姓名",
        onGuarantorAccountNo: "请填写担保人账户号码",
        onGuarantorBankName: "请填写担保人银行名称",
        onGuarantorBankAddress: "请填写担保人银行地址",
        onReferenceCompanyName: "请填写参考公司名称",
        onReferenceContactPerson: "请填写担保人联系人",
        onReferenceEmail: "请输入正确的担保人电子邮件地址",
        onReferenceContactNumber: "请输入有效的担保人联系号码",
        onReferenceMobileNumber: "请输入正确的担保人联系电话",
        onReferenceBillingAddress: "请填写参考的帐单地址",
        onReferenceShippingAdd: "请填写参考运输地址",
        onReferenceCountry: "请填写参考资料的国家",
        onReferenceAccHolderName: "请填写参考账户持有人姓名",
        onReferenceAccountNo: "请填写参考账户号码",
        onReferenceBankName: "请填写参考银行名称",
        onReferenceNatureOfBusiness: "请填写参考业务性质",
        onReferenceDORC: "请输入注册/证书的日期",
        onReferenceNOYB: "请输入营业年限",
        onICN: "请输入公司证书编号",
        onBankDocument: "请上传银行文件",
        onCompanyDocument: "请上传公司文件",
        saveAsDraft: "您的表格已被提交为草稿",
      },
      previous: "上一个",
      next: "下一个",
      faq: {
        heading: "常见问题",
        faq: "常问问题",
        myAuction: {
          question: "什么是我的拍卖会？",
          answer:
            "My Auction是一个门户租赁服务，在这里，拍卖商可以自己注册并创建他们自己的个性化拍卖，而竞标者可以选择他们希望参与的拍卖。",
        },
        privacyInsured: {
          question: "隐私保险呢？",
          answer: {
            firstLine:
              "My Auction是一个平台，建立的目的只是为了以最好的方式将拍卖商的货物介绍给竞标者，使竞标者对所提供的货物的状况和规格有充分的透明度和披露。",
            secondLine:
              "作为任何此类活动的一部分，不会与任何第三方在线广告商或网站或应用程序共享个人信息。My Auction不会向此类网站运营商或网络广告商提供与您的使用有关的任何信息。",
          },
        },
        whoIsAuctioneer: {
          question: "谁是拍卖师？",
          answer:
            "任何想出售（特此拍卖）他的货物如钻石、珠宝袋等的人都是拍卖师。少则10件，多则1000000件！与我们一起安排您的拍卖。 ",
        },
        whoIsBidder: {
          question: "谁是投标人？",
          answer: "任何想购买（特此拍卖）钻石、珠宝袋等商品的人都是投标人。 ",
        },
        howAuctioneerCreateAuctionWebsite: {
          question: "拍卖商如何创建拍卖网站？",
          answer: {
            firstLine: "拍卖商可以选择是否要进行拍卖 ",
            points: {
              firstPoint: "有自己的名字 例如",
              secondPoint: "不使用他们的名字 例如",
            },
            secondLine: "（征收的费用将相应改变）",
            thirdLine: "注册时要求提供第1类的子域名。 ",
          },
        },
        documentRequiredForRegisteration: {
          question: "注册所需的文件是什么？",
          answer: {
            firstLine: "在日本进行/参加拍卖会需要以下文件。",
            points: {
              firstPoint: "1.工读许可证",
              secondPoint: "2.公司注册文件（Tokibo/Tohon）",
              thirdPoint: "3.带照片的身份证明（驾驶执照/居留证/本人号码）",
              fourthPoint: "4. 财务副本（存折副本/银行对账单）",
            },
            secondLine:
              "对于日本以外的投标人，他们需要一个在日本的保证人公司。 ",
            thirdLine:
              "他的货物应以离岸买方提供的担保人的公司资料为基础进行结算。此后的出口等完全不属于我的拍卖会或拍卖商的范围。",
            fourthLine:
              "虽然我的拍卖公司在入职前对公司进行了彻底的核查，但我们仍然要求各拍卖商进行彻底的核查。",
          },
        },
        returnToWebsite: "返回到网站",
      },
    },
    blockUserPopup: {
      firstLine: "我们已经限制了您对MyAuctions.jp的访问。",
      secondLine: "请联系MyAuctions或给我们发邮件：contact@myauctions.jp",
    },
    paymentForm: {
      securePaymentGateway: "安全支付网关",
      payNow: "现在支付",
      amountToPay: "支付金额",
      paymentSuccessful: "支付成功",
      paymentSuccessfullMessage: "您将很快收到登录凭证",
      paymentFailed: "支付失败",
      paymentFailedMessage:
        "要么你的卡信息不正确，要么它没有被授权进行此类交易。请再试一次，如果你仍然面临问题，请联系管理员。",
    },
    aboutUs: {
      newPage: {
        meetTeam: "认识我们的团队",
        heading: "以客户满意度为动力。",
        heading1: "关怀发自内心的问题解决者。",
        subheading: "坚持不懈，持之以恒",
      },
      founder: {
        name: "Monika Jain",
        title: "创始人兼首席执行官",
        founderAndCEO: "创始人兼首席执行官莫妮卡-詹恩",
        founderFirstText:
          "莫妮卡-詹恩是一名印度土木工程师和在日本工作的连续企业家。詹恩在 World Economic Forum 上担任 Global Shaper 的重要成员。",
        founderSecondText:
          "今天，詹恩以她最大的知识、网络和解决问题的能力为初创企业提供服务。她与She loves tech、Google for Startups、500 Global和AWS Startups合作，支持在职母亲",
        founderThirdText:
          "詹恩也是MJ Co. Ltd.和GPB Infraprojects Pvt Ltd.公司的董事会成员。My Auctions是她在东京的第四家创业公司",
      },
      company: {
        companyProfie: "公司简介",
        tradeLabel: "公司名称",
        tradeValue: "My Auctions",
        HeaderQuaLabel: "总部地址",
        HeaderValue: "201 First Building, 3-10-1 Ueno Taitou Ku Tokyo 1100005",
        EstablishedLabel: "成立",
        EstablishedValue: "2020年8月5日",
        CEOLable: "CEO",
        CEOValue: "莫妮卡-贾恩",
        mainBusinessLabel: "业务领域",
        mainBusinessValue: "Auctions Rental Portal",
        corporateLabel: "公司编号",
        corporateValue: "0105-01-046069",
        bankLabel: "银行",
        bankValue: "朝日新干线银行",
      },
      mission: {
        missionLabel: "使命 ",
        missionValue: "使拍卖公平，容易，平等的为所有人服务。",
      },
      coFounders: {
        coFounder: "联合创始人",
        kanishk: {
          firstLine: "KANISHK CHORARIA",
          secondLine: "Mr. Foot",
          thirdLine:
            "具有超过12年的行业经验，被InterCon提名为50大技术领袖奖候选人。他总是以其组织的灵活性和卓越性为行业带来创造性的解决方案。",
        },
        pradeep: {
          firstLine: "PRADEEP SHARMA",
          secondLine: "CTO",
          thirdLine:
            "Pradeep Sharma是一位拥有20年技术专长的高级软件工程师。他曾担任技术负责人和IT项目经理。在My Auctions，夏尔马管理网络安全和网站界面。",
        },
        haruka: {
          firstLine: "HARUKA ARAI",
          secondLine: "日本典当拍卖公司Zipang Auctions的首席运营官",
          thirdLine:
            "拍卖会将在新井春芽 ,Arai San ,NJKP拍卖公司前总裁的专家指导下工作。能够从他在日本拍卖行业40年的资深经验中学习，我们不能再幸运了！",
        },
        shobhit: {
          firstLine: "SHOBHIT SUCHANTI",
          secondLine: "Mr. soul",
          thirdLine:
            "Shobhit Suchanti是一位具有GIA钻石资格的商人。Suchanti在日本的拍卖行业有10年的悠久历史。在这里，他掌握了向日本进口和出口钻石的方法。今天，Suchanti是My Auctions的一名领域专家",
        },
      },
    },
    mjDiamonds: {
      banner: {
        companyName: "MJ钻石",
        companyDescription: "购买钻石的天才之路",
        button: "开始出价",
      },
      daimodsInfo: {
        daimond: "钻石",
        auction: "拍卖",
        jewellery: "珠宝首饰",
      },
      companyInfo: {
        about: "关于",
        companyDescription:
          "致力于为我们的客户提供最好的价值，优质的服务和来自日本的广泛的钻石选择；通过内部网络购买。你所有的钻石和珠宝需求都可以在我们这里找到。我们的工作环境、流程和产出充满信心，目的是在客户、员工、银行家、供应商、祝福者和所有属于我们的大生态系统中建立持久的信任；在各个层面保持最高程度的透明度、诚实和商业道德。",
      },
      auctionInfo: {
        auction: "AUCTIONS",
        importantDaimond: "散装钻石和珠宝",
        auctionDescription:
          "日本领先的拍卖会之一，通过内部网络购买各种高品质的物品。我们提供的拍卖会有令人印象深刻的阵容，从日本到世界的各种钻石",
      },
      relevantInfo: {
        meetRelevant: "认识相关人员",
        relevantDescription:
          "为MJ钻石公司一起工作的人的描述可以在这里重新代表。他们可以提供多个图片，这些图片将以拼贴的形式出现（如出现在左侧）",
      },
    },
    subDomain: {
      banner: {
        companyName: "你的公司名称",
        companyDescription:
          "一个交互式的标语，用大约20-30个字来描述你的公司或其简介，将在这里更新",
        button: "开始竞标",
      },
      daimodsInfo: {
        watches: "手表",
        daimond: "钻石",
        auction: "拍卖",
        jewellery: "珠宝",
      },
      companyInfo: {
        about: "关于",
        companyDescription:
          "在你的拍卖中出现的商品描述将在本节中展示。这些商品可以是",
      },
      auctionInfo: {
        auction: "拍卖",
        importantDaimond: "拍卖品",
        auctionDescription:
          "在你的拍卖中出现的商品描述将在这一部分中展示。这些商品可以是",
      },
      relevantInfo: {
        meetRelevant: "认识相关人员",
        relevantDescription:
          "为abc公司一起工作的人的描述可以在这里体现。他们可以提供多张图片，这些图片将以拼贴画的形式出现（如出现在左侧）。",
      },
    },
    okura: {
      banner: {
        companyName: "大仓股份有限公司",
        companyDescription: '"通过再利用使世界变得有趣。"',
        button: "开始竞价",
      },
      daimodsInfo: {
        watches: "手表",
        daimond: "钻石",
        auction: "首饰",
        jewellery: "手表",
        watchlist: "watches",
      },
      companyInfo: {
        about: "关于",
        companyDescription:
          "我们在日本和海外从事手表、箱包和贵金属等旧货的批发和销售，以及国际珠宝的特别活动销售和拍卖业务",
      },
      auctionInfo: {
        auction: "拍卖",
        importantDaimond: "拍卖商品",
        auctionDescription:
          "我们展出的是品牌手表、品牌珠宝、非品牌珠宝、裸钻等。品牌珠宝卡地亚、梵克雅宝、蒂芙尼、宝格丽等。品牌手表方面，劳力士、欧米茄等",
      },
      relevantInfo: {
        meetRelevant: "认识相关人士",
        relevantDescription:
          "大仓股份有限公司成立。2019年7月1日 代表取缔役三木正也 办公室 〒812-0893 福冈县福冈市博多区那珂4-20-33 古代商业法许可证",
      },
      footer: {
        company: "MY AUCTIONS",
        copyright: "2020-21 保留所有权利 ",
        officeNumber: "O:  03-5829-9622",
        faxNumber: "传真",
        officeAddress:
          "2-4-10 Iwamotocho, Chiyoda-ku, Tokyo 101-0032 Odakyu Kanda Iwamotocho Building 3F, 4F",
        emailId: "-",
      },
    },
    nihonauction: {
      banner: {
        companyName: "nihonauction",
        companyDescription: "购买钻石的天才之路",
        button: "开始出价",
      },
      daimodsInfo: {
        daimond: "DIAMONDS",
        auction: "AUCTIONS",
        jewellery: "珠宝",
      },
      companyInfo: {
        about: "关于",
        companyDescription:
          " 致力于为我们的客户提供最好的价值，优质的服务和来自日本的广泛的钻石选择；通过内部网络购买。你所有的钻石和珠宝需求都可以在我们这里找到。我们的工作环境、流程和产出充满信心，目的是在客户、员工、银行家、供应商、祝福者和所有属于我们的大生态系统中建立持久的信任；在各个层面保持最高程度的透明度、诚实和商业道德",
      },
      auctionInfo: {
        auction: "AUCTIONS",
        importantDaimond: "散装钻石和珠宝",
        auctionDescription:
          "日本领先的拍卖会之一，通过内部网络购买各种高品质的物品。我们提供的拍卖会有令人印象深刻的阵容，从日本到世界的各种钻石",
      },
      relevantInfo: {
        meetRelevant: "认识相关人员",
        relevantDescription:
          "为MJ钻石公司一起工作的人的描述可以在这里重新代表。他们可以提供多个图片，这些图片将以拼贴的形式出现（如出现在左侧）",
      },
    },
    Tender: {
      tableColumnNames: {
        serialNo: "S. 编号",
        productId: "产品标识",
        Itemcode: "项目代码",
        sku: "SKU",
        lab: "证书实验室",
        certification: "认证",
        description: "描述",
        pricePct: "价格百分比",
        jewelleryPrice: "起始价格",
        yourBiddingPrice: "你的出价",
        notes: "注释",
        liveHighestBid: "现场最高出价",
        rapOff: "Rap 折扣",
        noOfBidders: "竞标者数量",
        timeLeft: "剩余时间",
        remark: "备注",
        startingPrice: "起拍价",
        Sieve: "筛子",
        ShowAll: "显示全部",
        Doyouwishtobid: "你是否希望出价",
        ActionsTender: "行动",
        carat: "克拉",
        color: "颜色",
        clarity: "净度",
        shape: "形状",
        cutGrade: "切割等级",
        noOfPieces: "件数",
        polish: "抛光",
        symmetryAbbreviation: "对称性",
        flour: "荧光",
        yourBid: "您的出价:",
        bulkBid: "批量出价",
        details: "细节",
        yourBidPrice: "你的出价",
        placeBid: "出价",
      },
      rapOff: "Rap 折扣",
      validation: {
        productSelection: "请一次只选择10个产品。",
      },
      bidInfo: {
        bidDescription: "你是否希望出价",
      },
      button: {
        showAll: "显示全部",
        placeBid: "出价",
      },
    },
    selby: {
      banner: {
        companyName: "到期日",
        companyDescription: "购买钻石的天才方法",
        button: "开始出价",
      },
      daimodsInfo: {
        daimond: "钻石",
        auction: "拍卖",
        jewellery: "珠宝首饰",
      },
      companyInfo: {
        about: "关于",
        companyDescription:
          "致力于为我们的客户提供最好的价值，优质的服务和来自日本的广泛的钻石选择；通过内部网络购买。你所有的钻石和珠宝需求都可以在我们这里找到。我们的工作环境、流程和产出充满信心，目的是在客户、员工、银行家、供应商、祝福者和所有属于我们的大生态系统中建立持久的信任；在各个层面保持最高程度的透明度、诚实和商业道德。",
      },
      auctionInfo: {
        auction: "拍卖",
        importantDaimond: "散装钻石和珠宝",
        auctionDescription:
          "日本领先的拍卖会之一，通过内部网络购买各种高品质的物品。我们提供的拍卖会有令人印象深刻的阵容，从日本到世界的各种钻石。",
      },
      relevantInfo: {
        meetRelevant: "认识相关人员",
        relevantDescription:
          "为MJ钻石公司一起工作的人的描述可以在这里重新代表。他们可以提供多个图片，这些图片将以拼贴的形式出现（如出现在左侧）",
      },
    },
    dialuster: {
      banner: {
        companyName: "Dialuster",
        companyDescription: "没有什么是不可能的",
        button: "开始竞价",
      },
      daimodsInfo: {
        daimond: "钻石",
        auction: "拍卖",
        jewellery: "珠宝首饰",
      },
      companyInfo: {
        about: "关于",
        companyDescription:
          " 致力于为我们的客户提供最好的价值，优质的服务和来自日本的广泛的钻石选择；通过内部网络购买。你所有的钻石和珠宝需求都可以在我们这里找到。我们的工作环境、流程和产出充满信心，目的是在客户、员工、银行家、供应商、祝福者和所有属于我们的大生态系统中建立持久的信任；在各个层面保持最高程度的透明度、诚实和商业道德。",
      },
      auctionInfo: {
        auction: "AUCTIONS",
        importantDaimond: "散装钻石和珠宝",
        auctionDescription:
          " 在Masamori motto的指导下，我们最近开始了我们的拍卖活动，从日本到世界的各种钻石和珠宝都有令人印象深刻的阵容。",
      },
      relevantInfo: {
        meetRelevant: "认识相关人员",
        relevantDescription:
          "为MJ钻石公司工作的人的描述可以在这里得到体现。他们可以提供多张图片，这些图片将以拼贴画的形式出现（如出现在左侧）",
      },
      footer: {
        company: "MY AUCTIONS",
        copyright: "2020-21 所有权利保留。",
        officeNumber: "O: 03-6421-8885",
        faxNumber: "传真",
        officeAddress: "5-16-17 Ueno Taitou Ku Tokyo",
        emailId: "dialuster@gmail.com",
      },
    },
    kiraga: {
      banner: {
        companyName: "珠宝制造商 - KIRAGA",
        companyDescription: "购买钻石的天才之路",
        companyDescriptionNew:
          "一家位于静冈县孙藤郡的珠宝制造商。我们制造、加工、批发和零售珠宝。请参观位于艳阳山的沙龙。我们也有一个展览。",
        button: "开始竞标",
      },
      daimodsInfo: {
        daimond: "钻石",
        auction: "拍卖",
        jewellery: "珠宝首饰",
      },
      companyInfo: {
        about: "关于",
        companyDescription:
          "作为一家位于静冈县孙藤郡的珠宝制造商，我们生产、加工、全国范围内批发和零售珠宝。",
      },
      auctionInfo: {
        auction: "拍卖",
        importantDaimond: "松散的珠宝",
        auctionDescription:
          "日本领先的拍卖会之一，通过内部网络购买各种高品质的物品。我们提供的拍卖会有一个令人印象深刻的阵容，从日本到世界的钻石种类最多。",
      },
      relevantInfo: {
        meetRelevant: "会见相关人士",
        relevantDescription:
          '我们带着精心挑选的珠宝、开放花园、熟练的工匠和帮助引导塑造顾客思想的员工，等待您的光临。当你听到 "珠宝店 "这个词时，希望你能想到它。',
        relevantDescriptionAdd: "'有点难以进入，但请随时来参观我们。",
      },
      footer: {
        company: "MY AUCTIONS",
        copyright: "2020-21 保留所有权利。 ",
        officeNumber: "O: 055-980-1888",
        faxNumber: "传真",
        officeAddress:
          "121, 608 Higashino, Nagaizumi-cho, Sunto-gun, Shizuoka Prefecture",
        emailId: "",
      },
      releventHeader: {
        relevantHeader: "这是由一个珠宝制造商赞助的经济实惠拍卖会。",
        firstdescription: "这将是一个只邀请的拍卖会，如果你想参加的话",
        seconddescription: "要在Twitter或e-shop@kiraga.co.jp",
        thirdDescription:
          "请与我联系。拍卖会将在Twitter上举行，或请与我联系。拍卖会将在Twitter上举行，或者我们在Instagram上公布。",
        descriptionAdd:
          "请注意，在成功出价时，除了中标价之外，还需要缴纳消费税。.",
      },
    },
    takeyama: {
      banner: {
        companyName: "Takeyama Co., Ltd.",
        companyDescription:
          "自1947年以来，我们一直在东京的Okachimachi生产钻石和彩色宝石首饰。",
        button: "开始竞标",
      },
      daimodsInfo: {
        daimond: "钻石",
        auction: "拍卖",
        jewellery: "珠宝首饰",
      },
      companyInfo: {
        about: "关于",
        companyDescription:
          "自1947年以来，我们一直在东京的Okachimachi制造钻石和彩色宝石首饰",
      },
      auctionInfo: {
        auction: "拍卖",
        importantDaimond: "松散的珠宝",
        auctionDescription:
          "日本领先的拍卖会之一，通过内部网络购买各种高品质的物品。我们提供的拍卖会有一个令人印象深刻的阵容，从日本到世界的钻石种类最多。",
      },
      relevantInfo: {
        meetRelevant: "认识相关人员",
        relevantDescription:
          "为MJ钻石公司一起工作的人的描述可以在这里重新代表。他们可以提供多个图片，这些图片将以拼贴的形式出现（如出现在左侧）",
      },
      footer: {
        company: "MY AUCTIONS",
        copyright: "2020-21 所有权利保留。",
        officeNumber: "O: 03-3834-5050",
        faxNumber: "传真 ",
        officeAddress: "110-0015 东京都台东区东园1丁目17-3",
        emailId: "",
      },
    },
    inquiry: {
      support: "SUPPORT",
      Inquiry: "查询",
      button: {
        submit: "提交",
        Reply: "回复",
        bidNow: "现在出价",
      },
      submitQueriy: "提交您的查询",
      submitDescription: "写下您对我的拍卖的查询，您希望查询的是",
      singleProduct: "把你对单个产品的查询放在这里",
      typeMessege: "键入一个信息",
      placeYourQuery: "将您的查询放在这里。",
      fields: {
        Section: "部分",
        Status: "状态",
        Action: "行动",
        Pending: "待办的",
        Replied: "已回复",
        Resolved: "已解决",
        Product: "产品",
        Other: "其他",
        Query: "查询",
        Attachment: "附件",
        AttachmentLink: "附件链接",
        ChooseFile: "选择文件",
      },
      toastString: {
        messageSent: "消息已发送",
      },
      popupString: "将在24小时内尽快给您回复。",
      popupStringFirst: "我们已经收到你关于我的拍卖的查询。",
      inquiryText:
        "欢迎来到我的拍卖会查询空间。如果您有任何疑问，我们在线并可与您交谈",
    },
    toastTraslation: {
      ParticipationMailAlreadySent: "已发送参与邮件",
      AddedIntoWatchlistsuccessfully: "成功添加到观察名单",
      Removedfromwatchlistsuccessfully: "成功从观察名单中删除",
      Notesaved: "注释保存成功",
      PrebidPriceSavedSuccessfully: "投标前价格成功保存",
      PreBidPriceRemovedsuccessfully: "投标前价格成功删除",
      Operationnotallowed: "操作不允许",
      messageSent: "信息已发送",
      replyText: "请输入信息",
    },
    hokuyu: {
      banner: {
        companyName: "Hokuyu",
        companyDescription: "直接来自日本的回收珠宝",
        button: "开始竞拍",
      },
      daimodsInfo: {
        daimond: "钻石",
        auction: "拍卖",
        jewellery: "珠宝首饰",
      },
      companyInfo: {
        about: "关于",
        companyDescription:
          "致力于为我们的客户提供最好的价值，优质的服务和来自日本的广泛的珠宝选择；通过内部网络购买。您的所有珠宝需求都可以在我们这里找到。我们的工作环境、流程和产出充满信心，目的是在客户、员工、银行家、供应商、祝福者和所有属于我们的大生态系统中建立持久的信任；在各个层面保持最高程度的透明度、诚实和商业道德",
      },
      auctionInfo: {
        auction: "AUCTIONS",
        importantDaimond: "松散的珠宝",
        auctionDescription:
          "日本领先的拍卖会之一，通过内部网络购买各种高质量的物品。我们提供的拍卖会有一个令人印象深刻的阵容，从日本到世界的最大品种的珠宝",
      },
      relevantInfo: {
        meetRelevant: "认识相关人员",
        relevantDescription:
          "为Hokuyu一起工作的人的描述可以在这里重新代表。他们可以提供多个图片，这些图片将以拼贴的形式出现（如出现在左侧）",
      },
    },
    network: {
      compeleteProfile: "请完成您的个人资料",
      internetConnection: "请检查您的网络连接并刷新页面",
    },
    FreePersonalizedWebsites: {
      text: {
        textfirst: "免费的个性化网站",
        textsecond: "在线拍卖不再是过去的事情了",
        textSecondOne: "今天就为你的在线拍卖获得端到端的解决方案！",
        third: "当你想提升到一个新的水平时，我们已经有了。",
        four: "平台来扩展你的业务。",
      },
      CreateWebSite: "创建你的免费网站",
      CreateWebSiteSecond: "创建免费拍卖网站",
      sliderText: {
        textFirst: "寻找一个能满足你对拍卖网站需求的平台",
        textSecond: "建立更智能的工作流程，为你做决定。",
        textThird: "我的拍卖会的特点是，一键创建自己的免费个性化网站。",
      },
      websiteForm: {
        EnterDetails: "输入你的详细资料",
        Name: "名称",
        CompanyName: "公司名称",
        Address: "地址",
        ChooseFile: "选择文件",
      },
    },
    pendingProduct: {
      HeaderName: {
        productId: "产品标识",
        biddingPrice: "投标价格",
        sellingPrice: "售价",
        description: "描述",
        negotiate: "协商",
        auctionName: "拍卖名称",
      },
      button: {
        negotiate: "协商",
        yes: "是",
        no: "否",
        cancel: "取消",
        ok: "可以",
        submit:"提交",
        okay:'好的'

      },
      text: {
        DoYouWishNegotiatePopupFirst: "你希望产品以底价出售吗？",
        DoYouWishNegotiatePopupSecond: "的售价上",
        descriptionFirstText: "请输入产品的协商价格",
        descriptionSecondText: "底价",
        descriptionThird: " 而销售价格是  ",
        enterNegotiateValue: "输入协商价格",
        enterNegotiateValuegreater: "协商价格应高于你的出价",
      },
      modal: {
        winnerText: "产品以底价出售",
        negotiationWinnerText:"获胜价格 - ",
        negotiationSuccessfully:" 协商价格已成功提交"
      },
    },
    newLandingPage: {
      signUp: {
        heading: "免费获得所有拍卖和创建无限的拍卖",
        subHeading: "开始您的14天试用",
        buttonText: "开始免费试用",
        formHeading: "开始注册",
        formHeading2: "拍卖马上开始",
        errorMessage: "请填写所需信息",
      },
      otpText: "请输入发送给您的代码进行验证。",
    },
    newCommanTrans: {
      Seller: "售卖者",
      Bidder: "出价者",
      Cart: "卡",
      Itemcode: "项目編號",
      Date: "日期",
      preBiddingprice: "预标价格",
      productdescription: "商品描述",
      state: "狀態",
      startingprice: "起始价格",
      sellingprice: "售价",
      finalbidprice: "最终拍卖价格",
      SKU: "SKU",
      totalAmount: "总金额",
      rowsperpage: "每页行数",
      hold: "已预留",
      sold: "已售出",
      productID: "产品ID",
      notificationdate: "",
      auctionname: "拍卖名称",
      notificationtype: "通知类型",
      description: "描述",
      notifications: "提示",
      soldproduct: "售出货品",
      customMessage: "自定信息",
      deleteProduct: "删除货品",
      holdProduct: "预留货品",
      outBid: "出价",
      filters: "过滤器",
      all: "全部",
      reset: "调整",
      next: "下一个",
      selectAll: "选择全部",
      serialNo: "序列号",
      noOfBiddersBids: "投标号码/投标",
      remarks: "备注",
      replied: "已回复",
      sorrynomatchingrecordsfound: "抱歉，没有找到匹配的记录",
      diamondandParcel: "钻石和包裹",
      jewellery: "珠宝",
      negotiate: "谈判",
      calenderMonths: "",
      timeleft: "剩余时间",
      home: "主页",
      preview: "预告",
      auction: "拍卖",
      watchlist: "观察列表",
      invoice: "发票",
      profile: " 列表",
      product: "产品",
      other: "其他",
      totalAmounts: "总金额",
      surelogout: "你确定要登出吗？",
      CANCEL: "取消",
      OK: "OK",
      prductDaysauction: "365 Days auction",
      productDaysAuctionInvoices: "365 Days Auction Invoices",
      givedetails: "Give details",
      getyourAuctionapproved: "Get your Auction approved",
      sellandEnjoy: "Sell and Enjoy",
    },
    zipangLanding: {
      tab: {
        upcomingAuc: '即将举行的拍卖',
        about: "关于",
        sell: '出售',
        buy: '购买',
        contact: '联系我们',
        ourTeam: 'OUR TEAM',
      },
      ourTeam: {
        ourTeamheading: 'Our Team',
        ourteamSubHeading: 'Empowering Bidding Brilliance Unveiled At Zipang',
        whyChooseus: 'WHY CHOOSE US?',
        seemLessHeading: 'Seamless Bidding: Where Satisfaction Meets Every Auction',
        team: {
          member1: {
            name: 'SHOBHIT SUCHANTI',
            title: 'President',
            description: 'Shobhit Suchanti is a GIA diamond-qualified merchant. Suchanti has a long history of 10 years in the Japanese auction industry. Here, he mastered importing and exporting diamonds to Japan. Today, Suchanti is a domain expert at My Auctions.'
          },
          member2: {
            name: 'HARUKA ARAI',
            title: 'COO',
            description: "The auction will be working under the expert guidance of 新井春芽 ,Arai San , Ex president of NJKP Auction. Getting to learn from his 40 years of veteran experience in the Japan Auction Industry, we couldn't be more fortunate!"
          },
          member3: {
            name: 'SARTHAK JAIN',
            title: 'HK Office Correspondent',
            description: 'Sarthak Jain serves as the head sales manager at the Zipang Auctions Japan and Hong Kong office. With Jain’s dedication and hardwork, the team at Zipang Auctions continues to thrive and excel in the competitive world of natural diamond auctions. Jain ensures that the office in Hong Kong operates seamlessly.'
          },
          member4: {
            name: 'YUKO OGINO',
            title: 'Tokyo Office Correspondent',
            description: 'Yuko Ogino is recognized as the longest-serving Japanese correspondent at the Zipang Auctions office in Japan. With a wealth of experience, Ogino nurtures strong client relationships, handles inquires and meticulously photographs diamonds from all directions for auction purposes.'
          },
        },
        seller: {
          tile1: {
            heading: 'World-class buyers',
            description: 'India, China, America etc.  A large number of strong buyers have passed our screening and proven their suitability.  A network of approximately 9,000 companies.'
          },
          tile2: {
            heading: 'Even people who are not good at computers are welcome.',
            description: 'Leave it to us.  We will take care of your important items responsibly.  The customer is in control of the transparent auction.'
          },
          tile3: {
            heading: '360° image capture',
            description: 'Sending 360° shooting videos to the world.  Buyers from all over the world can view your items online.'
          },
          tile4: {
            heading: 'Supported by a specialized team with strong overseas expertise',
            description: 'All customer personal information is protected by encryption.  Those who register as buyers are required to submit documents such as ID and business history, and their identity is rigorously screened.'
          },
        },
        buyer: {
          tile1: {
            heading: 'High quality product lineup from Japan Pawn Market',
            description: 'Access to an assortment of QUALITY diamonds handpicked by our Diamond merchants suitable for your market.'
          },
          tile2: {
            heading: 'Professional Team for your support',
            description: 'Leave it to us.  We will take care of your important items responsibly.  The customer is in control of the transparent auction.'
          },
          tile3: {
            heading: 'Buy from comfort of your seat',
            description: 'The Diamonds come with every minute detail possible, 360degree video, images with loupe enabling you to buy diamonds in Japan without the loss of precious time,expenses while removing the language barriers.'
          },
          tile4: {
            heading: 'Taking the Japan Pawn Auctions to the internet age',
            description: 'Our 10 sec bidding auction, not only provides an exciting experience but also  levels up transparency and accountability. Take your paddle up and win the stone, online from your phone!'
          },
        }
      },
      heading: {
        fistHeading: '',
        secHeading: '',
        subHading: '',
        upcomingAuctions: '',
        seller: '卖家',
        buyer: '出价人',
      },
      button: {
        sell: "出售",
        buy: '购买',
        reserveSlot: '点击此处预约观看',
      },
      sellerTrans: {
        firstBox: {
          heading: '全球顶级买家',
          description: '外国語のスキルや直接交渉は必要ありません。当社のクライアントはすべて、審査を通過した国際取引の承認メンバーです。',
        },
        secondBox: {
          heading: '信誉',
          description: '専門家チームが鑑定し、証明書や画像だけでなく、お客様のご興味のある商品に関するあらゆるご質問にお答えいたします。',
        },
        thirdBox: {
          heading: '透明度',
          description: 'オークションの最終的な主導権はお客様にあります。マイページからお客様の石の詳細を確認することができます。',
        },
        GiveDetails: {
          title: '1. 提供详细信息',
          heading: '通过电子邮件/whatsapp/line/wechat，以csv格式提交您要提交的宝石清单，我们的专家团队将开始工作！',
          subHeading: '',
        },
        sendGoods: {
          title: '2. 发送货物',
          heading: '将您附在清单中的货物发送到我们的地址，我们的团队将在核对清单后及时通知您！',
          subHeading: '日本： 110-0016 东京都台东区台东 4 丁目 10-7',
          thirdSubHeading: '香港 香港红磡民乐街 21 号焦点工业中心 A 座 5 楼 11 室 3.',
        },
        sellorEnjoy: {
          title: '3. 销售与享受',
          heading: '我们利用自己的专业知识、技术和人脉，为珠宝卖家的珠宝争取最大的回报。',
          subHeading: '',
        }
      },
      buyerTrans: {
        firstBox: {
          heading: '商品阵容',
          description: '您可以在自己的电脑或移动设备上浏览 JAPAN PAWN MARKET 的大量精选商品。我们的终极目标是只提供优质商品！',
        },
        secondBox: {
          heading: '精通技术',
          description: '能够在日本购买钻石，无需浪费宝贵的时间和旅行费用，避免了出口公司收取的运输佣金和当地消费税融资。钻石的每一个微小细节、360 度视频、放大镜图片以及我们所有的专家指导都将一一呈现。',
        },
        thirdBox: {
          heading: '透明度和可信度',
          description: '除证书和图片外，我们的专家团队还将对您感兴趣的物品进行鉴定，并回答您提出的所有问题！',
        },
      },
      registerTitle: '注册。 获得验证。 购买一切！',
      footer: {
        japanAddress: "",
        honkongAddress: "",
        clientDetails: "",
        contactSection: {
          email: '',
          TermsandService: '',
          companyDetails: ''
        }
      }
    }
  },
  trans: {
    error: "错误",
    removeFromWatchList: "成功从观察名单中删除",
    fieldSelect: "--选择--",
    pleaseEnterFromWeight: "请从重量输入",
    supportCaps: "帮助",
    mazal: "成交!!",
    endDateGreaterThanStartDate: "结束日期应大于开始日期",
    pleaseVerifyCaptchs: "请验证验证码",
    productDescriptionError: "请提供产品描述。",
    subdomainAlreadyExists: "子域已经退出",
    saveAsDraft: "保存为草稿",
    businessDetails: "企业详细信息",
    businessRegistrationNumber:
      "商业注册号码/公司证书或许可证或证据，这使你有权经营这个特定的业务",
    dateOfRegAndCertificate: "注册/证书的日期",
    incorporateCertificate: "公司证书",
    noOfYearsInBusiness: "经营年限",
    natureOfBusiness: "业务性质",
    kycReferenceCompany: "参考公司（最好是My Auctions认可的用户）",
    kycReferenceDetails: "参考细节",
    kycOtherGroupCompany: "其他集团公司/姐妹公司/联营公司等（非必须填写）",
    noteAddedSuccessfully: "注释添加成功",
    prebidPriceSavedSuccessfully: "投标前价格保存成功",
    prebidPriceRemovedSuccessfully: "成功删除投标前的价格",
    addToWatchlistSuccessfully: "成功添加到观察名单",
    removedFromWatchlistSuccessfully: "成功从观察名单中删除",
    errorMessage: {
      operationNotAllowed: "不允许操作",
      tokenExpired: "代币过期",
      notAllowedToAccess: "你不允许访问我的拍卖，请订阅以获得完整的访问权。.",
      dontHaveInvitationToAccess: "你没有任何访问邀请。",
      accessRestrictedContactUs:
        "我们已经限制了您对MyAuctions.jp的访问。请联系MyAuctions或给我们发邮件：contact@myauctions.jp",
      paymentSucess: "支付成功",
      otpExpired: "Otp过期",
      unknownErrorServerSideException: "来自服务器的未知错误",
      userPaymentFail: "付款时发生错误",
    },
  },
};