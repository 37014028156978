import React, { useEffect } from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { convertProductPrice } from "../TableProps";
import ProductDescription from "../../shared/ProductDescription";

const StyledComponent = styled(Grid)`
  outline: none;
  .secondaryLabel {
    font-size: 1.8em;
    font-weight: bold;
    color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  }
  .text {
    font-size: 1em;
    font-weight: bold;
    padding: 10px;
    color: ${(props) => props.theme.colors.base.black};
  }

  .textDesc {
    font-size: 1.2em;
    font-weight: bold;
    padding: 8px;
    color: ${(props) => props.theme.colors.base.black};
  }

  .paddingBottom {
    padding-bottom: 20px;
  }
`;

const StyledWrapper = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  width: 300px;
  text-align: center;
  padding: 2em;
  border-radius: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 300px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 400px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 500px;
  }
`;

const StyledTextWrapper = styled.div`
  font-family: Calibre-Regular;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 1.5em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 2em;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    font-size: 2.5em;
  }
`;

const StyledWinnerWrapper = styled(Grid)`
  text-align: center;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    padding: 1em 1em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    padding: 1em 2em;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    padding: 1em 2em;
  }
`;

export interface IProps {
  closeModal: () => void;
  negotiableProductDetails: any;
}

const ViewHoldProductWinnerModal = React.forwardRef((props: IProps, ref) => {
  const { closeModal, negotiableProductDetails } = props;
  
  const {t} = useTranslation();
  useEffect(() => {
    setTimeout(() => {
      closeModal();
    }, 2100);
  });

  return (
    <StyledComponent tabIndex={-1}>
      <StyledWrapper container spacing={0}>
        <StyledWinnerWrapper item md={12} xs={12} lg={12}>
          <StyledTextWrapper className="fontBold">
            {t("common:home:modal:congratulations")}
          </StyledTextWrapper>
          <div className="secondaryLabel paddingBottom">MAZAL!!</div>
          <div className="textDesc">
            <span>{t("common:pendingProduct:modal:winnerText")}</span>{" "}
            {convertProductPrice(
              negotiableProductDetails,
              negotiableProductDetails === undefined ||
                negotiableProductDetails === null
                ? 0
                : negotiableProductDetails.caratSellingPrice
            )}
          </div>
          <div className="text">
            <ProductDescription
              truncateLength={65}
              data={negotiableProductDetails}
              isTendor={false}
            />
          </div>
        </StyledWinnerWrapper>
      </StyledWrapper>
    </StyledComponent>
  );
});

export default ViewHoldProductWinnerModal;
